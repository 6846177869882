<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="12"
    >
      <v-card v-if="saleOrder">
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="appLogo"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3 "
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ appName }}
                </span>
              </div>

              <!-- TODO: Handle Company Information API -->
              <span class="d-block">
                <span>{{ t('Email:') }} </span>
                <a
                  target="_blank"
                  href="mailto:info@iconhairspa.dk"
                >
                  info@iconhairspa.dk
                </a>
              </span>
              <span class="d-block">
                <span>{{ t('Website:') }} </span>
                <a
                  target="_blank"
                  href="https://www.iconhairspa.dk"
                >
                  www.iconhairspa.dk
                </a>
              </span>
              <span class="d-block">Juliesmindevej 12, 4180 Sorø, DK</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4">
                {{ t('Order') }} #{{ saleOrder.number }}
              </p>
              <p class="mb-2">
                <span>{{ t('Posting Date:') }} </span>
                <span class="font-weight-semibold">{{ saleOrder.postingDate | toLocalDate }}</span>
              </p>
              <p class="mb-2">
                <span>{{ t('Order Date:') }} </span>
                <span class="font-weight-semibold">{{ saleOrder.orderDate | toLocalDate }}</span>
              </p>
              <p class="mb-2">
                <span>{{ t('Requested Delivery Date:') }} </span>
                <span class="font-weight-semibold">{{ saleOrder.requestedDeliveryDate | toLocalDate }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div class="mb-8 mb-sm-0">
              <p class="font-weight-semibold payment-details-header">
                {{ t('Order To:') }}
              </p>
              <p class="mb-1">
                {{ saleOrder.customerName }}
              </p>
              <!-- <p class="mb-1">
                {{ saleOrder.company }}
              </p> -->
              <p class="mb-1">
                {{ saleOrder.sellToAddressLine1 }}, {{ saleOrder.sellToPostCode }} {{ saleOrder.sellToCity }}, {{ saleOrder.sellToCountry }}
              </p>
              <p class="mb-1">
                {{ saleOrder.phoneNumber }}
              </p>
              <p class="mb-0">
                {{ saleOrder.email }}
              </p>
            </div>
            <div>
              <p class="font-weight-semibold payment-details-header">
                {{ t('Bill To:') }}
              </p>
              <table>
                <tr>
                  <td class="pe-6">
                    {{ t('Total Due:') }}
                  </td>
                  <td>
                    <span>{{ saleOrder.currencyCode }} {{ saleOrder.totalAmountIncludingTax }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('Customer:') }}
                  </td>
                  <td>
                    {{ saleOrder.customerName }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('Address:') }}
                  </td>
                  <td class="d-flex flex-column">
                    <span>{{ saleOrder.billToAddressLine1 }}</span>
                    <span v-if="saleOrder.billToAddressLine2">
                      <small>{{ saleOrder.billToAddressLine2 }}</small>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('City:') }}
                  </td>
                  <td>
                    {{ saleOrder.billToPostCode }} {{ saleOrder.billToCity }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('Country:') }}
                  </td>
                  <td>
                    {{ saleOrder.billToCountry }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ t('NUMBER') }}
                </th>
                <th>
                  {{ t('DESCRIPTION') }}
                </th>
                <th>
                  {{ t('UNIT PRICE') }}
                </th>
                <th>
                  {{ t('QUANTITY') }}
                </th>
                <th>
                  {{ t('DISCOUNT') }}
                </th>
                <th>
                  {{ t('TAX') }}
                </th>
                <th>
                  {{ t('TOTAL AMOUNT') }}
                  <br />
                  <small tclass="text--secondary">{{ ('EXCLUDING TAX') }}</small>
                </th>
                <th>
                  {{ t('TOTAL PRICE') }}
                  <br />
                  <small tclass="text--secondary">{{ ('INCLUDING TAX') }}</small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in saleOrder.saleOrderLines"
                :key="item.id"
              >
                <template v-if="item.lineType === 'Comment' && item.description">
                  <td>
                    <span class="text--secondary font-weight-semibold">
                      <small>{{ t('COMMENT') }}</small>
                    </span>
                  </td>
                  <td colspan="7">
                    <span>{{ item.description }}</span>
                  </td>
                </template>

                <template v-else>
                  <td class="text-no-wrap">
                    <router-link
                      v-if="item.lineType === 'Item'"
                      :to="{ name : 'item-view', params : { id : item.itemId } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      <span>{{ item.lineObjectNumber }}</span>
                    </router-link>

                    <span v-else>{{ item.lineObjectNumber }}</span>
                  </td>
                  <td class="text-no-wrap">
                    <router-link
                      v-if="item.lineType === 'Item'"
                      :to="{ name : 'item-view', params : { id : item.itemId } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      <span>{{ item.description }}</span>
                    </router-link>

                    <span v-else>{{ item.description }}</span>
                  </td>
                  <td>
                    <span>
                      {{ item.unitPrice }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.quantity }}
                    </span>
                  </td>
                  <td>
                    <div class="d-flex flex-column flex-grow-1">
                      <span>{{ item.discountAmount }}</span>
                      <span class="text--secondary"><small>{{ item.discountPercent }}%</small></span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column flex-grow-1">
                      <span>{{ item.totalTaxAmount }}</span>
                      <span><small class="text--secondary">{{ item.taxPercent }}%</small></span>
                    </div>
                  </td>
                  <td>
                    <span>
                      {{ item.amountExcludingTax }}
                    </span>
                  </td>
                  <td>
                    <span>
                      {{ item.amountIncludingTax }}
                    </span>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <div class="mb-2 mb-sm-0">
              <p class="mb-1">
                <span class="font-weight-semibold">{{ t('Salesperson:') }}</span> <span>{{ saleOrder.salesperson }}</span>
              </p>
              <p>{{ t('Thanks for your business') }}</p>
            </div>
            <div>
              <table>
                <tr>
                  <td class="pe-16">
                    {{ t('Subtotal:') }}
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <span>{{ saleOrder.currencyCode }} </span>
                    <span>{{ saleOrder.totalAmountExcludingTax }}</span>
                    <!-- <span v-if="!saleOrder.discountAppliedBeforeTax">{{ saleOrder.totalAmountExcludingTax }}</span> -->
                    <!-- <span v-if="saleOrder.discountAppliedBeforeTax">{{ saleOrder.totalAmountExcludingTax - saleOrder.discountAmount }}</span> -->
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    {{ t('Discount:') }}
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <span>{{ saleOrder.currencyCode }} </span>
                    <span>{{ saleOrder.discountAmount }}</span>
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    {{ t('Tax:') }}
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <span>{{ saleOrder.currencyCode }} </span>
                    <span>{{ saleOrder.totalTaxAmount }}</span>
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    {{ t('Total:') }}
                  </td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    <span>{{ saleOrder.currencyCode }} </span>
                    <span>{{ saleOrder.totalAmountIncludingTax }}</span>
                    <!-- <span v-if="saleOrder.discountAppliedBeforeTax">{{ saleOrder.totalAmountIncludingTax }}</span> -->
                    <!-- <span v-if="!saleOrder.discountAppliedBeforeTax">{{ saleOrder.totalAmountIncludingTax - saleOrder.discountAmount }}</span> -->
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text v-if="saleOrder.workDescription">
          <p class="font-weight-medium text-lg text--primary mb-4">
            {{ t('Work Description') }}
          </p>

          <p class="mb-1">
            {{ saleOrder.workDescription }}
          </p>
        </v-card-text>

        <!-- <v-card-text class="
            px-8
            py-6"
          >
          </p><p class="mb-0">
            <span class="font-weight-semibold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!</span>
          </p>
        </v-card-text> -->
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <!-- <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            color="primary"
            class="mb-3"
            block
            @click="isInvoiceSendSidebarActive = true"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn>
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
          >
            Download
          </v-btn>
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            @click="printInvoice"
          >
            Print
          </v-btn>
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }"
          >
            Edit Invoice
          </v-btn>
          <v-btn
            color="success"
            block
            @click="isAddPaymentSidebarActive = true"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            <span>Add Payment</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col> -->

    <!-- invoice send drawer -->
    <!-- <invoice-sidebar-send-invoice v-model="isInvoiceSendSidebarActive"></invoice-sidebar-send-invoice> -->

    <!-- invoice add payment drawer -->
    <!-- <invoice-sidebar-add-payment v-model="isAddPaymentSidebarActive"></invoice-sidebar-add-payment> -->
  </v-row>
</template>

<script>
import { useRouter } from '@core/utils'
import { onUnmounted } from '@vue/composition-api'
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'

import themeConfig from '@themeConfig'

import { useUtils } from '@core/libs/i18n'
import useSaleOrderView from './useSaleOrderView'

// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  components: {
    // InvoiceSidebarSendInvoice,
    // InvoiceSidebarAddPayment,
  },
  setup() {
    const { route } = useRouter()
    const { t } = useUtils()

    const {
      loading,
      saleOrder,

      init,
      destroy,
    } = useSaleOrderView(route.value.params.id)

    init()
    onUnmounted(() => {
      destroy()
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      t,

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },

      loading,
      saleOrder,

      printInvoice,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
